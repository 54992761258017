import React from 'react';
import Seo from '../components/molecules/Seo';
import {graphql} from 'gatsby';
import PageHeroImage from '../components/organisms/PageHeroImage';
import TwoColumn from '../components/organisms/TwoColumn';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import Container from '../components/atoms/Container';
import Card from '../components/molecules/Card';
import PageTitle from "../components/atoms/PageTitle";
import PrismicRichText from "../components/atoms/PrismicRichText";
import Breadcrumbs from "../components/molecules/Breadcrumbs";


const Holder = styled.article`
  background-color: ${props => props.theme.colours.grey};
`;

const PostHolder = styled.article`
  border-top: 1px solid ${props => props.theme.colours.green};
  padding: 3rem 0 6rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 5rem 0 6rem 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin: 0;
    @media( ${props => props.theme.breakpoints.md} ) { 
      grid-template-columns: 1fr 1fr;
    }
    li {
      
    }
  }
`;


function NewsListPage( { data } ) {
  const {
    hero,
    intro,
    strapline,
    page_title,
  } = data.prismicNews.data;
  const posts = data.allPrismicNewsPost.nodes;
  return (
    <>
      <Seo title={page_title ? page_title.text : 'News'}/>
      <PageHeroImage image={hero}/>
      <Holder>
        <PageTitle>{page_title ? page_title.text : 'News'}</PageTitle>
        <TwoColumn>
          <div className="large-mobile">
            <PrismicRichText render={strapline.raw}/>
          </div>
          <div>
            <PrismicRichText render={intro.raw}/>
          </div>
        </TwoColumn>
        <Container>
          <PostHolder>
            <ul>
              {posts && posts.map( post =>
                <li key={post.id}>
                  <Card
                    image={post.data.preview_image}
                    excerpt={<RichText render={post.data.excerpt.raw}/>}
                    slug={`news/${post.uid}`}
                    formattedTitle={<PrismicRichText render={post.data.title.raw}/>}/>
                </li>
              )}
            </ul>
          </PostHolder>
        </Container>
        <Breadcrumbs>
          {page_title ? page_title.text : 'News'}
        </Breadcrumbs>
      </Holder>
    </>
  )
}

export default NewsListPage;

export const newsListPageQuery = graphql`
    query newsListPageQuery($skip: Int!, $limit: Int!) {
        prismicNews {
            data {
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                intro {
                    raw
                }
                page_title {
                    text
                }
                strapline {
                    raw
                }
            }
        }
        allPrismicNewsPost(
            limit: $limit
            skip: $skip
            sort: {fields: first_publication_date, order: DESC}
        ) {
            nodes {
                id
                uid
                data {
                    preview_image {
                        alt
                        fluid(maxWidth: 1000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                    title {
                        raw
                        text
                    }
                    excerpt {
                        raw
                    }
                }
            }
        }
    }

`;
